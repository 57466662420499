import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Moment from "react-moment"
import Sticky from "react-sticky-el"
import { Container } from "reactstrap"
import Seo from "../components/seo"
import Devlogo from "../images/logo2-90x90.png"

import "./category.css"

const slugify = require("slugify")
const Category = ({ data, pageContext, location }) => {
  const posts = data.categorizePosts.edges

  const category =
    posts[0].node.categories.length > 0 ? posts[0].node.categories[0].title : ""

  const limit = 10
  let recentPostCount = 0

  // console.log("data", category)

  return (
    <Layout location={location}>
      <Seo
        title={` Archives - Quandary Consulting Group`}
        description="description"
      />
      <div className="page-content mt-4">
        <section className="category-section component">
          <Container>
            <div className="row">
              <div className="col-md-9">
                <div className="blog-content-wrapper">
                  <div className="category-lists x-">
                    {posts.map(blog => {
                      const BlogImg = getImage(
                        blog.node.mainImage !== null
                          ? blog.node.mainImage.asset.gatsbyImageData
                          : "https://source.unsplash.com/user/joshhild/500x500"
                      )
                      const BlogBgImg = convertToBgImage(BlogImg)

                      return (
                        <article className="category-list-item">
                          <div className="category-featured">
                            <Link to={`/${blog.node.slug.current}/`} className>
                              <BackgroundImage
                                Tag="div"
                                className="post-image"
                                // Spread bgImage into BackgroundImage:
                                {...BlogBgImg}
                                preserveStackingContext
                              ></BackgroundImage>
                            </Link>
                            <div className="category-meta">
                              <div className="category-author-holder">
                                <div className="category-author-name">
                                  {" "}
                                  <span>by</span>{" "}
                                  <Link
                                    to={`/author/${slugify(
                                      blog.node.author
                                        ? blog.node.author.name.toLowerCase() +
                                            "/"
                                        : "dev-team/"
                                    )}`}
                                    title={`Posts by ${
                                      blog.node.author
                                        ? blog.node.author.name
                                        : "Dev Team"
                                    }`}
                                    rel="author"
                                  >
                                    {blog.node.author
                                      ? blog.node.author.name
                                      : "Dev Team"}
                                  </Link>
                                </div>
                                <div className="item-date mt-1">
                                  <Moment
                                    add={{ days: 1, hours: 9 }}
                                    format="MMMM DD, YYYY"
                                  >
                                    {blog.node.publishedAt
                                      ? blog.node.publishedAt
                                      : blog.node._createdAt}
                                  </Moment>
                                </div>
                              </div>
                              <div className="category-author-image">
                                <GatsbyImage
                                  style={{
                                    width: 43,
                                    height: 43,
                                  }}
                                  image={
                                    blog.node.author
                                      ? blog.node.author.image.asset
                                          .gatsbyImageData
                                      : Devlogo
                                  }
                                  alt={
                                    blog.node.author
                                      ? blog.node.author.name
                                      : "Dev Team"
                                  }
                                  imgClassName="author"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="category-holder">
                            <div className="category-category w-100">
                              {blog &&
                              blog.node &&
                              blog.node.categories &&
                              blog.node.categories.length !== 0
                                ? blog.node.categories.map((ct, idx) => {
                                    const slug = slugify(ct.title.toLowerCase())
                                    return (
                                      <Link
                                        className="text-small font-weight-medium text-uppercase"
                                        to={`/category/${slug}/`}
                                      >
                                        {(idx ? ", " : "") + ct.title}
                                      </Link>
                                    )
                                  })
                                : null}
                            </div>
                            <h4 className="category-title mt-2">
                              <Link
                                aria-label="link"
                                to={`/${blog.node.slug.current}/`}
                              >
                                {blog.node.title}
                              </Link>
                            </h4>

                            <div className="category-content">
                              <p>{blog.node.shortDescription}</p>
                            </div>

                            <div className="category-more">
                              <Link
                                className="font-weight-medium"
                                to={`/${blog.node.slug.current}/`}
                              >
                                Read Now <i className="item-arrow" />
                              </Link>
                            </div>
                          </div>
                        </article>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="block col-md-3">
                <div className="blog-single-content-sidebar pr-lg-0">
                  <Sticky
                    boundaryElement=".block"
                    hideOnBoundaryHit={true}
                    topOffset={50}
                  >
                    <div className="recent-post-sidebar">
                      <ul className="list-unstyled">
                        <li className="sideline-category-head">
                          <b>{category}</b>
                          <p>Resources</p>
                        </li>

                        <li>
                          {(() => {
                            if (category === "BTaaS") {
                              return (
                                <Link
                                  className="sideline-category-title"
                                  to={
                                    "/process-improvement-cut-waste-and-improve-business-efficiency/"
                                  }
                                >
                                  <p>The Process Improvement Guide</p>
                                </Link>
                              )
                            } else if (category === "Procurement") {
                              return (
                                <Link
                                  className="sideline-category-title"
                                  to={
                                    "/the-procurement-management-strategic-playbook/"
                                  }
                                >
                                  <p>The Procurement Management Playbook</p>
                                </Link>
                              )
                            } else {
                              return <span></span>
                            }
                          })()}
                        </li>

                        {data.allPost.edges.map(({ node }) => {
                          if (recentPostCount >= limit) return
                          if (
                            node.categories.length > 0 &&
                            node.categories[0].title === category
                          )
                            recentPostCount = recentPostCount + 1
                          return (
                            <>
                              {node.categories.length > 0 &&
                                node.categories[0].title === category && (
                                  <li>
                                    <Link
                                      className="sideline-category-title"
                                      to={`/${node.slug.current}/`}
                                    >
                                      {node.title}
                                    </Link>
                                  </li>
                                )}
                            </>
                          )
                        })}
                      </ul>
                    </div>
                  </Sticky>
                </div>
                {/* <Search />
                  <div className="recent-post">
                    <h5 className="font-weight-medium mt-0">Recent Posts</h5>
                    <ul className="list-unstyled pt-3">
                      {data.allSanityPost.edges.slice(0, 4).map(({ node }) => {
                        return (
                          <li>
                            <p className="text-teal font-weight-bold mb-1">
                              <Moment
                                add={{ days: 1, hours: 9 }}
                                format="MMMM DD, YYYY"
                              >
                                {node.publishedAt
                                  ? node.publishedAt
                                  : node._createdAt}
                              </Moment>
                            </p>
                            <Link
                              className="text-secondary font-weight-medium"
                              to={`/${node.slug.current}/`}
                            >
                              {node.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div> */}
              </div>
            </div>
          </Container>
        </section>
      </div>
    </Layout>
  )
}
export default Category

export const blogQuery = graphql`
  query CategoryTemplateQuery($title: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    categorizePosts: allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: $title } } } }
      sort: { order: ASC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          _createdAt
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
          author {
            id
            name
            image {
              asset {
                _id
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
          body
          shortDescription
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          publishedAt
          _createdAt
          slug {
            current
          }
        }
      }
    }
    allPost: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          title
          categories {
            title
          }
          publishedAt
          _createdAt
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
                width: 380
              )
            }
          }
        }
      }
    }
  }
`
